import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";

import "./App.css";
// import { Sidebar, Navbar, Footer, ThemeSettings } from "./components";
// import { useStateContext } from "./contexts/ContextProvider";
import Login from "./pages/register/login";
import Home from "./Home";

const App = () => {
  return (
    <>
      <BrowserRouter>
              <Routes>
                {/* dashboard  */}
                <Route path="*" element={<Home/>} />
                <Route path="/login" element={<Login/>} />
              </Routes>
      </BrowserRouter>
      </>
  );
};

export default App;
