import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useUI } from "../../components/UIContext";
// import { set } from "react-hook-form";
import { ORDER } from "../../gql/order";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useStateContext } from "../../contexts/ContextProvider";


const Order_Detail = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const {currentColor} = useStateContext();

    const { data, error } = useQuery(ORDER, {
        variables: { id: id }, fetchPolicy: "network-only"
    })

    console.log("error", data)

    if (!data) {
        return "Loading";
    }

    return (
        <div>
            <div className=" ml-10 mt-5">
                <button className=" px-3 py-2 rounded-sm"
                    onClick={() => navigate("/order")}
                    style={{ background: currentColor }}
                ><IoMdArrowRoundBack className=" text-xl text-white" />
                </button>
            </div>
            <div class="bg-white max-w-full shadow overflow-hidden sm:rounded-lg m-20">
            <div class="px-4 py-5 sm:px-6">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Order
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Details and informations about order.
                </p>
            </div>
            <div class="border-t border-gray-200">
                {
                    <div className="grid lg:grid-cols-2 sm:grid-cols-none">
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                ID:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.id}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                User Name:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.user.name?data.user_order_by_pk.user.name : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Total Price:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.total_price?data.user_order_by_pk.total_price : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Total Quantity:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.total_quantity? data.user_order_by_pk.total_quantity : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Order Status:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.order_status ? data.user_order_by_pk.order_status : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Note:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.notes ? data.user_order_by_pk.notes : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Address:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.address? JSON.parse(data.user_order_by_pk.address).address : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                User's City:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.address? JSON.parse(data.user_order_by_pk.address).city : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                User Phone Number:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.address? JSON.parse(data.user_order_by_pk.address).phone : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Order ID:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.order_readable_id? data.user_order_by_pk.order_readable_id : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Payment Method:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.payment_method? data.user_order_by_pk.payment_method : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Payment Service Name:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.payment_service_name !== "null" ? data.user_order_by_pk.payment_service_name : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Payment Receiver Name:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.payment_receiver_name !== "null" ? data.user_order_by_pk.payment_receiver_name : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Payment Receiver Account Number:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.payment_receiver_account_number !== "null" ? data.user_order_by_pk.payment_receiver_account_number : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Verify:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.verified ? data.user_order_by_pk.verified : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Remark:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.remarks ? data.user_order_by_pk.remarks : "-"}
                            </div>
                        </div>
                        <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <div class="text-sm font-medium text-gray-500">
                                Payment Image:
                            </div>
                            <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {data.user_order_by_pk.payment_method === "Banking" ? <img src={data.user_order_by_pk.payment_screenshot_image_url} width={"200px"} height={"60px"} alt="jhjhhj" /> : "-"}
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div class="px-4 py-5 sm:px-6">
                <span class="">
                    <button className=" px-4 py-1 text-white rounded-sm" disabled={data.user_order_by_pk.order_status === "COMPLETED"} 
                    style={{
            background: data.user_order_by_pk.order_status === "COMPLETED" ? 'grey' : currentColor}} onClick={() => navigate(`/deliver/${data.user_order_by_pk.id}`)}>Deliver</button>
                </span>
            </div>
        </div>
        </div>
    )
}

export default Order_Detail;