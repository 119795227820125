import { gql } from "@apollo/client";

export const ORDERS = gql`
query Orders {
    user_order(order_by: {updated_at: desc}) {
      created_at
      fk_user_id
      id
      order_readable_id
      order_status
      payment_screenshot_image_url
      total_price
      total_quantity
      updated_at
      user {
        name
        id
      }
    }
    user_order_aggregate {
      aggregate {
        count
      }
    }
  }
  
  
  
`;

export const ORDER = gql`
query Order ($id : uuid!) {
  user_order_by_pk(id: $id) {
    address
    created_at
    fk_user_id
    id
    notes
    order_status
    payment_method
    payment_receiver_account_number
    payment_receiver_name
    payment_screenshot_image_url
    payment_service_name
    remarks
    total_price
    total_quantity
    order_readable_id
    verified
    updated_at
    user {
      name
      id
    }
  }
}

`;
