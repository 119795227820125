import { useQuery } from "@apollo/client";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useStateContext } from "../../contexts/ContextProvider";
import { ORDER } from "../../gql/order";

const Deliver = () => {
  const [loading, setLoading] = useState(false);
  const merchantId = window.localStorage.getItem("userId");
  const [onlineCouriers, setOnlineCouriers] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [cAddress, setCAddress] = useState("");
  const [cPhone, setCPhone] = useState("");
  const [pickUpName, setPickUpName] = useState("");
  const [pickUpAddress, setPickUpAddress] = useState("");
  const [cNote, setCNote] = useState("");
  const [mNote, setMNote] = useState("");
  const [pLatitude, setPLatitude] = useState();
  const [pLongitude, setPLongitude] = useState();
  const [cLatitude, setCLatitude] = useState();
  const [cLongitude, setCLongitude] = useState();
  const [courierId, setCourierId] = useState("");
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentColor } = useStateContext();

  const { data, error } = useQuery(ORDER, {
    variables: { id: id },
    fetchPolicy: "network-only",
  });

  const fetchCourier = async () => {
    try {
      const response = await fetch(
        `https://api.delivery.axra.app/api/rest/online-courier/${merchantId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setOnlineCouriers(result.online_couriers);
      console.log("result", result);
    } catch (error) {}
  };

  // useEffect(() => {
  //     fetchCourier();
  // }, []);

  console.log("online", onlineCouriers);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorObject = {};
    let isError = false;
    setLoading(true);

    if (!customerName) {
      isError = true;
      errorObject.customerName = "Customer Name is required!";
    }
    if (!cAddress) {
      isError = true;
      errorObject.cAddress = "Customer Address is required!";
    }
    if (!cPhone) {
      isError = true;
      errorObject.cPhone = "Customer Phone is required!";
    }
    if (!pickUpName) {
      isError = true;
      errorObject.pickUpName = "Pick Up Name is required!";
    }
    if (!pickUpAddress) {
      isError = true;
      errorObject.pickUpAddress = "Pick Up Address is required!";
    }
    if (!cLatitude) {
      isError = true;
      errorObject.cLatitude = "Customer Latitude is required!";
    }
    if (!cLongitude) {
      isError = true;
      errorObject.cLongitude = "Customer Longitude is required!";
    }
    if (!pLatitude) {
      isError = true;
      errorObject.pLatitude = "Pick Up Latitude is required!";
    }
    if (!pLongitude) {
      isError = true;
      errorObject.cLongitude = "Pick Up Longitude is required!";
    }
    if (!courierId) {
      isError = true;
      errorObject.courierId = "Courier is required!";
    }

    if (isError) {
      setErrors(errorObject);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        "http://104.248.99.193:8090/api/rest/insert-orders",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fk_merchant_id: merchantId,
            customer_address: cAddress,
            customer_name: customerName,
            customer_phone: cPhone,
            fk_courier_id: courierId,
            pickup_address: pickUpAddress,
            customer_notes: cNote,
            merchant_notes: mNote,
            order_id: id,
            pickup_latitude: parseFloat(pLatitude),
            pickup_longitude: parseFloat(pLongitude),
            customer_latitude: parseFloat(cLatitude),
            customer_longitude: parseFloat(cLongitude),
            pickup_name: pickUpName,
          }),
        }
      );
      const result = await response.json();
      console.log(result);
      alert("Deliver Create Successfully", result);
      navigate("/OrderDeliver");
      setLoading(false);
    } catch (error) {
      console.log("Error on Server", error);
      setLoading(false);
    }
  };

  return (
    <div className="py-10 sm:py-15">
      <div className=" ml-10 mt-5">
        <button
          className=" px-3 py-2 rounded-sm"
          onClick={() => navigate("/order")}
          style={{ background: currentColor }}
        >
          <IoMdArrowRoundBack className=" text-xl text-white" />
        </button>
      </div>
      <div className="mx-20 mt-4">
        <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
          Create Order Deliver Here
        </h2>
      </div>
      <form action="#" method="POST" className="mt-10 mx-20  sm:mt-15">
        <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Customer Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Customer Name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.customerName && (
                <span className="error text-red-500 mb-2">
                  {errors.customerName}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Customer Address
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Customer Address"
                value={cAddress}
                onChange={(e) => setCAddress(e.target.value)}
                autoComplete="organization"
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.cAddress && (
                <span className="error text-red-500 mb-2">
                  {errors.cAddress}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Customer Phone
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Customer Phone Number"
                autoComplete="organization"
                value={cPhone}
                onChange={(e) => setCPhone(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.cPhone && (
                <span className="error text-red-500 mb-2">{errors.cPhone}</span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Pick Up Name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Pick Up Name"
                autoComplete="organization"
                value={pickUpName}
                onChange={(e) => setPickUpName(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.pickUpName && (
                <span className="error text-red-500 mb-2">
                  {errors.pickUpName}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Pick Up Address
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Pick Up Address"
                autoComplete="organization"
                value={pickUpAddress}
                onChange={(e) => setPickUpAddress(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.pickUpAddress && (
                <span className="error text-red-500 mb-2">
                  {errors.pickUpAddress}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Customer Note
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Customer Note"
                autoComplete="organization"
                value={cNote}
                onChange={(e) => setCNote(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Merchant Note
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Merchant Note"
                autoComplete="organization"
                value={mNote}
                onChange={(e) => setMNote(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Pick Up Latitude
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Pick Up Latitude"
                autoComplete="organization"
                value={pLatitude}
                onChange={(e) => setPLatitude(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.pLatitude && (
                <span className="error text-red-500 mb-2">
                  {errors.pLatitude}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Pick Up Longitude
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Pick Up Longitude"
                autoComplete="organization"
                value={pLongitude}
                onChange={(e) => setPLongitude(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.pLongitude && (
                <span className="error text-red-500 mb-2">
                  {errors.pLongitude}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Customer Latitude
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Customer Latitude"
                autoComplete="organization"
                value={cLatitude}
                onChange={(e) => setCLatitude(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.cLatitude && (
                <span className="error text-red-500 mb-2">
                  {errors.cLatitude}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2">
            <label
              htmlFor="task"
              className="block text-sm font-semibold leading-6 text-gray-900"
            >
              Customer Longitude
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                name="task"
                id="task"
                placeholder="Enter Customer Longitude"
                autoComplete="organization"
                value={cLongitude}
                onChange={(e) => setCLongitude(e.target.value)}
                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              {errors.cLongitude && (
                <span className="error text-red-500 mb-2">
                  {errors.cLongitude}
                </span>
              )}
            </div>
          </div>
          <div className="sm:col-span-2" onClick={fetchCourier}>
            <label
              htmlFor="countries"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Select Courier
            </label>
            <select
              id="countries"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              // selected={() => handleBlur("merchantId")}
              value={courierId}
              onChange={(e) => setCourierId(e.target.value)}
            >
              <option selected>Select Courier</option>
              {onlineCouriers.map((row, index) => (
                <option selected value={row.id}>
                  {row.name}
                </option>
              ))}
            </select>
            {errors.courierId && (
              <span className="error text-red-500 mb-2">
                {errors.courierId}
              </span>
            )}
          </div>
        </div>
        <div className="mt-10">
          <button
            type="submit"
            className="rounded-md px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
            disabled={loading}
            style={{ background: currentColor }}
          >
            {loading ? "Processing..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Deliver;
