import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { IoMdArrowRoundBack } from "react-icons/io";

const Detail_Order_Deliver = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentColor } = useStateContext();
  const [deliver, setDeliver] = useState([]);

  const fetchDeliver = async () => {
    try {
      const response = await fetch(
        `https://api.delivery.axra.app/api/rest/orders/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setDeliver(result.orders_by_pk);
    } catch (error) {
      console.error("Error fetching deliveries:", error);
    }
  };

  useEffect(() => {
    fetchDeliver();
  }, [id]);

  return (
    <div>
      <div className=" ml-10 mt-5">
        <button
          className=" px-3 py-2 rounded-sm"
          onClick={() => navigate("/OrderDeliver")}
          style={{ background: currentColor }}
        >
          <IoMdArrowRoundBack className=" text-xl text-white" />
        </button>
      </div>
      <div class="bg-white max-w-full shadow overflow-hidden sm:rounded-lg m-16">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">Order</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Details and informations about order.
          </p>
        </div>
        <div class="border-t border-gray-200">
          {
            <div className="grid lg:grid-cols-2 sm:grid-cols-none">
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">ID:</div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver?.id}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">Order ID:</div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.order_id}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Merchant Note:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.merchant_notes ? deliver.merchant_notes : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Courier Name:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.courier?.name ? deliver.courier.name : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Courier Latitude:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.courier?.courier_latitude
                    ? deliver.courier.courier_latitude
                    : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Courier Longitude:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.courier?.courier_longitude
                    ? deliver.courier.courier_longitude
                    : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Customer Name:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.customer_name ? deliver.customer_name : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Customer Phone:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.customer_phone ? deliver.customer_phone : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Customer Note:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.customer_notes ? deliver.customer_notes : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Customer Address:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.customer_address ? deliver.customer_address : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Customer Latitude:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.customer_latitude ? deliver.customer_latitude : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Customer Longitude:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.customer_longitude
                    ? deliver.customer_longitude
                    : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Pick Up Name:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.pickup_name ? deliver.pickup_name : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Pick Up Address:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.pickup_address ? deliver.pickup_address : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Pick Up Latitude:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.pickup_latitude ? deliver.pickup_latitude : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Pick Up Longitude:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.pickup_longitude ? deliver.pickup_longitude : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Tracking Number:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.tracking_number ? deliver.tracking_number : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">
                  Order Status:
                </div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.order_status_id ? deliver.order_status_id : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">Created At:</div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.created_at ? deliver.created_at.slice(0, 10) : "-"}
                </div>
              </div>
              <div class="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <div class="text-sm font-medium text-gray-500">Updated At:</div>
                <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {deliver.updated_at ? deliver.updated_at.slice(0, 10) : "-"}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Detail_Order_Deliver;
