import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";

import "./App.css";
import { Sidebar, Navbar, Footer, ThemeSettings } from "./components";
import { useStateContext } from "./contexts/ContextProvider";
import { Dashboard } from "./pages";
import Order_Detail from "./pages/order/Order_Detail";
import Orders from "./pages/order/Orders";
import Deliver from "./pages/order/Deliver";
import OrderDeliver from "./pages/orderDeliver/OrderDeliver";
import Detail_Order_Deliver from "./pages/orderDeliver/Detail_Order_Deliver";



const Home = () => {
    const {
        setCurrentColor,
        setCurrentMode,
        setThemeSettings,
        currentMode,
        activeMenu,
        themeSettings,
        currentColor,
    } = useStateContext();

    const navigate = useNavigate();

    useEffect(() => {
        const currentThemeColor = localStorage.getItem("colorMode");
        const currentThemeMode = localStorage.getItem("themeMode");
        if (currentThemeColor && currentThemeMode) {
            setCurrentColor(currentThemeColor);
            setCurrentMode(currentThemeMode);
        }
    }, []);

    useEffect(() => {
        const userId = window.localStorage.getItem("userId");
        if(!userId) {
            navigate("/login");
        }
    },[]);

    return (
        <div className={currentMode === "Dark" ? "dark" : ""}>
                <div className="flex relative dark:bg-main-dark-bg">
                    <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
                        <button
                            type="button"
                            onClick={() => setThemeSettings(true)}
                            style={{ background: currentColor, borderRadius: "50%" }}
                            className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                        >
                            <FiSettings />
                        </button>
                    </div>
                    {activeMenu ? (
                        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                            <Sidebar />
                        </div>
                    ) : (
                        <div className="w-0 dark:bg-secondary-dark-bg">
                            <Sidebar />
                        </div>
                    )}
                    <div
                        className={
                            activeMenu
                                ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
                                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
                        }
                    >
                        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                            <Navbar />
                        </div>
                        <div>
                            {themeSettings && <ThemeSettings />}
                            <Routes>
                                {/* dashboard  */}
                                <Route path="dashboard" element={<Dashboard />} />
                                <Route path="order" element={<Orders/>} />
                                <Route path="detail_order/:id" element={<Order_Detail/>} />
                                <Route path="deliver/:id" element={<Deliver/>} />
                                <Route path="OrderDeliver" element={<OrderDeliver/>} />
                                <Route path="detail_orderdeliver/:id" element={<Detail_Order_Deliver/>} />
                            </Routes>
                        </div>
                        {/* <Footer /> */}
                    </div>
                </div>
        </div>
    );
};

export default Home;
