import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ORDERS } from '../../gql/order';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getLink } from '../..';
import Pagination from '../../components/Pagination';


const Orders = () => {
    const navigate = useNavigate();
    const [orderies, setOrderies] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    // const {data:resultOrder,error}=useQuery(ORDERS);

    const [loadOrder, resultOrder] = useLazyQuery(ORDERS);

    useEffect(() => {
        loadOrder({
            fetchPolicy: "network-only",
        });
    }, [loadOrder]);

    useEffect(() => {
        if (resultOrder.data) {
            console.log("rerror", resultOrder.error);
            setOrderies(resultOrder.data.user_order);
        }
    }, [resultOrder]);
    
    const total_orders = Math.ceil(resultOrder.data?.user_order_aggregate.aggregate.count);

    const totalPages = Math.ceil(resultOrder.data?.user_order_aggregate.aggregate.count/itemsPerPage);

    const currentData = orderies?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    
    const calculateOrderNumber = (index) => {
        return (currentPage - 1) * itemsPerPage + index + 1;
    };
    
    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    }

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    }

    // useEffect(() => {
    //     setOrderies(resultOrder.user_order);
    // }, [resultOrder])

    return (
        <div>
            <div>
                <div className="px-20">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr className="text-center text-sm">
                                <th scope="col" className="px-6 py-3">No.</th>
                                <th scope="col" className="px-6 py-3">User Name</th>
                                <th scope="col" className="px-6 py-3">Total Price</th>
                                <th scope="col" className="px-6 py-3">Total Quantity</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(currentData) && currentData.map((row, index) => (
                                    <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 text-center" key={index}>
                                        <td className="px-6 py-4">{calculateOrderNumber(index)}</td>
                                        <td className="px-6 py-4">{row.user.name}</td>
                                        <td className="px-6 py-4">{row.total_price}</td>
                                        <td className="px-6 py-4">{row.total_quantity}</td>
                                        <td className="px-6 py-4">
                                            <button className=" bg-gray-500 px-2 py-1 text-white rounded-lg" onClick={() => navigate(`/detail_order/${row.id}`)}>Detail</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            total_orders={total_orders}
            onNextPage={handleNextPage}
            onPrevPage={handlePrevPage}
            />
        </div>
    )
}

export default Orders;