import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { getLink } from '../..';
// import Pagination from '../../components/Pagination';

const OrderDeliver = () => {
  const navigate = useNavigate();
  const [deliver, setDelivers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(""); // State for selected date
  const merchantId = window.localStorage.getItem("userId");

  const fetchDeliver = async () => {
    try {
      const response = await fetch(
        `https://api.delivery.axra.app/api/rest/order/${merchantId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const result = await response.json();
      setDelivers(result.orders);
    } catch (error) {
      console.error("Error fetching deliveries:", error);
    }
  };

  useEffect(() => {
    fetchDeliver();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const filteredDeliveries = selectedDate
    ? deliver.filter(
        (row) => row.created_at && row.created_at.includes(selectedDate)
      )
    : deliver;

  // const total_orders = Math.ceil(resultOrder.data?.user_order_aggregate.aggregate.count);

  // const totalPages = Math.ceil(resultOrder.data?.user_order_aggregate.aggregate.count/itemsPerPage);

  // const currentData = orderies?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // const calculateOrderNumber = (index) => {
  //     return (currentPage - 1) * itemsPerPage + index + 1;
  // };

  // const handleNextPage = () => {
  //     setCurrentPage((prevPage) => prevPage + 1);
  // }

  // const handlePrevPage = () => {
  //     setCurrentPage((prevPage) => prevPage - 1);
  // }

  // useEffect(() => {
  //     setOrderies(resultOrder.user_order);
  // }, [resultOrder])

  return (
    <div>
      <div>
        <div className="px-20">
          <input
            type="date"
            id="date-filter"
            className="mr-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
            value={selectedDate || ""}
            onChange={(e) => handleDateChange(e.target.value)}
          />

          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="text-center text-sm">
                <th scope="col" className="px-6 py-3">
                  No.
                </th>
                <th scope="col" className="px-6 py-3">
                  Order ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Merchant Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Customer Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Order Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Created Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {filteredDeliveries.map((row, index) => (
                <tr
                  className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 text-center"
                  key={index}
                >
                  <td>{index + 1}</td>
                  <td className="px-6 py-4">
                    {row.order_id ? row.order_id : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {row.merchant.name ? row.merchant.name : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {row.customer_name ? row.customer_name : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {row.order_status_id ? row.order_status_id : "-"}
                  </td>
                  <td className="px-6 py-4">
                    {row.created_at ? row.created_at.slice(0, 10) : "-"}
                  </td>
                  <td className="px-6 py-4">
                    <button
                      className=" bg-gray-500 px-2 py-1 text-white rounded-lg"
                      onClick={() => navigate(`/detail_orderdeliver/${row.id}`)}
                    >
                      Detail
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                total_merchants = {total_merchants}
                onNextPage={handleNextPag}
                onPrevPage={handlePrevPage}
            /> */}
    </div>
  );
};

export default OrderDeliver;
